<template>
    <div class="add-mod-group row no-gutters">
        <template v-if="currentView === 'index'">
            <line-item
                v-if="canDo('addNew')"
                class="col-12">
                <slab-button @click.native="currentView = 'new'">Create new mod group</slab-button>
            </line-item>

            <template v-if="canDo('addExisting') && groups.length">
                <div class="existing-header col-12">
                    <h3>Select an existing Mod Group</h3>

                    <SearchBar
                        bound
                        @search="search"
                    />
                </div>

                <div class="col-12">
                    <GroupListItem
                        v-for="group in groups"
                        :key="group.id"
                        :item="group"
                        :children="group.children"
                        @click.native="saveModGroup(group)"
                    />
                </div>
            </template>
        </template>

        <template v-else-if="currentView === 'new'">
            <context-title
                :passedActions="saveActions"
                :bare="true"
                class="add-mod-group__context-title col-12"
                :title="identifyingTitle"
            />

            <mod-group-form
                :products="products"
                :passedModGroup="modGroup"
                :posMods="posMods"
                :integrated="integrated"
                class="col-12"
                @formChange="setModGroup"
                @removeMod="$emit('removeMod', arguments[0])"
            />
        </template>

    </div>
</template>

<script>
import { removeDups } from 'helpers'
import ContextTitle from 'components/context_title'
import SlabButton from 'components/slab_button'
import ModGroupForm from 'components/mod_group_form'
import GroupListItem from 'components/GroupListItem'
import SearchBar from 'components/search_bar'
import LineItem from 'components/line_item'

export default {
    name: 'AddModGroup',

    // Methods

    components: {
        ContextTitle,
        SlabButton,
        ModGroupForm,
        GroupListItem,
        SearchBar,
        LineItem,
    },

    // Props

    props: {
        productName: String,
        products: Array,
        passedModGroup: [Object, Boolean],
        modGroups: Array,
        permissions: {
            type: Array,
            default: () => [
                'addNew',
                'addExisting',
            ],
        },
        posMods: {
            type: Array,
            default: () => [],
        },
        integrated: {
            type: Boolean,
            default: false,
        },
    },

    // Data

    data() {
        return {
            currentView: 'index',
            modGroup: false,
            searchQuery: '',
            saveActions: [
                {
                    type: 'proceed',
                    display: 'Save',
                    run: () => {
                        this.$emit('save', this.modGroup)
                    },
                },
            ],
        }
    },

    computed: {
        identifyingTitle() {
            return `Mod Group for ${this.productName}`
        },
        groups() {
            const sorted = removeDups(this.modGroups.sort((a, b) => {
                if (a.title > b.title) {
                    return 1
                }

                if (a.title < b.title) {
                    return -1
                }

                return 0
            }), 'id')

            if (this.searchQuery && this.searchQuery.length) {
                return sorted.filter(group => group.title.match(new RegExp(this.searchQuery, 'i')))
            }

            return sorted
        },
    },

    // Hooks

    created() {
        if (this.passedModGroup) {
            this.setModGroup(this.passedModGroup)
            this.currentView = 'new'
        } else if (this.canDo('addNew') && (!this.canDo('addExisting') || !this.groups.length)) {
            this.currentView = 'new'
        }
    },

    // Methods

    methods: {
        setModGroup(group) {
            const newGroup = {
                ...group,
                children: group.children.map(mod => ({...mod, groupName: group.title})),
            }

            this.modGroup = newGroup
        },

        saveModGroup(modGroup = false) {
            const group = modGroup ? modGroup : this.modGroup

            this.$emit('save', group)
        },

        canDo(action) {
            return this.permissions.indexOf(action) !== -1
        },

        search(query) {
            this.searchQuery = query
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~scss/mixins';
            .context-title {
            padding-left: 0px !important;
        }
.add-mod-group {
    &__context-title {
        border-top: 1px solid  $grey_light_1;
    }
}

.existing-header {
    display: flex;
    padding: spacing(sm);
    justify-content: space-between;

    @include below(md) {
        display: block;

        .search-bar {
            padding-top: 15px;
        }
    }
}
</style>
