var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "draggable",
    {
      attrs: {
        options: {
          handle: ".explorer-item__handle",
        },
      },
      on: { update: _vm.handleUpdate },
      model: {
        value: _vm.items,
        callback: function ($$v) {
          _vm.items = $$v
        },
        expression: "items",
      },
    },
    _vm._l(_vm.items, function (child, i) {
      return _c("ExplorerItem", {
        key: i,
        attrs: {
          index: i,
          title: _vm.resolveChildTitle(child),
          children: _vm.resolveChildren(child),
          checked: _vm.resolveChildStatus(child),
          checkLabel: _vm.childCheckLabel,
          item: child,
          resolveChildTitle: _vm.resolveGrandChildTitle,
          permissions: _vm.permissions,
          sortable: _vm.sortable,
        },
        on: {
          editChild: _vm.onEditChild,
          removeChild: _vm.onRemoveChild,
          selectGrandChild: _vm.onSelectGrandChild,
          removeGrandChild: _vm.onRemoveGrandChild,
          checkChild: _vm.onCheckChild,
          reorder: function ($event) {
            return _vm.handleGrandChildReorder(child, i, arguments[0])
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }