var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "variation-builder block-xs--md-bottom row no-gutters" },
    [
      _vm.value.length
        ? [
            _c("div", { staticClass: "variation-builder__table col-12" }, [
              _c("div", [
                _c(
                  "div",
                  { staticClass: "line" },
                  _vm._l(_vm.struct, function (prop) {
                    return _c(
                      "div",
                      {
                        key: prop.display,
                        staticClass: "variation-builder__table-data",
                        style: { flex: prop.size || 1 },
                      },
                      [_vm._v(_vm._s(prop.display))]
                    )
                  }),
                  0
                ),
              ]),
              _c(
                "div",
                _vm._l(_vm.value, function (variation, i) {
                  return _c(
                    "div",
                    { key: i, staticClass: "line" },
                    [
                      _vm._l(_vm.struct, function (prop) {
                        return _c(
                          "div",
                          {
                            key: prop.name,
                            staticClass: "variation-builder__table-data",
                            class: { small: _vm.small },
                            style: { flex: prop.size || 1 },
                          },
                          [
                            prop.name
                              ? [
                                  _c("form-input", {
                                    attrs: {
                                      disabled:
                                        _vm.disabled &&
                                        !_vm.canForceEdit(prop.name),
                                      bare: true,
                                      full: true,
                                      spellcheck: "true",
                                    },
                                    model: {
                                      value: variation[prop.name],
                                      callback: function ($$v) {
                                        _vm.$set(variation, prop.name, $$v)
                                      },
                                      expression: "variation[prop.name]",
                                    },
                                  }),
                                ]
                              : [
                                  _c(prop.render(variation, i), {
                                    tag: "component",
                                  }),
                                ],
                          ],
                          2
                        )
                      }),
                      _c(
                        "div",
                        { staticClass: "variation-builder__delete-container" },
                        [
                          _c("div", {
                            staticClass:
                              "variation-builder__button icon icon--remove block-xs--xs-left",
                            on: {
                              click: function ($event) {
                                return _vm.removeVariation(i, variation)
                              },
                            },
                          }),
                        ]
                      ),
                    ],
                    2
                  )
                }),
                0
              ),
            ]),
          ]
        : [
            _c("p", [
              _vm._v(
                "There does not appear to be any " +
                  _vm._s(_vm.entityName) +
                  "s for this item. Click below to add one."
              ),
            ]),
          ],
      _c(
        "text-button",
        {
          staticClass: "block-xs--md-top col-12",
          attrs: { type: "add" },
          nativeOn: {
            click: function ($event) {
              return _vm.addVariation.apply(null, arguments)
            },
          },
        },
        [_vm._v("Add " + _vm._s(_vm.entityName))]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }