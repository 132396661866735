var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "explorer-item" }, [
    _c("div", { staticClass: "explorer-item__content" }, [
      _c(
        "div",
        {
          staticClass: "explorer-item__header",
          on: { click: _vm.handleHeaderClick },
        },
        [
          _c("div", { staticClass: "explorer-item__handle-title" }, [
            _vm.sortable
              ? _c("div", {
                  staticClass: "explorer-item__handle icon icon--handle",
                })
              : _vm._e(),
            _c("div", { staticClass: "explorer-item__title" }, [
              _vm._v(" " + _vm._s(_vm.title) + " "),
            ]),
          ]),
          _vm.hasPermission("viewGrandChildren")
            ? _c("div", {
                staticClass: "explorer-item__indicator icon",
                class: { "icon--plus": !_vm.active, "icon--minus": _vm.active },
              })
            : _vm._e(),
        ]
      ),
      _vm.hasPermission("viewGrandChildren")
        ? _c(
            "div",
            {
              staticClass: "explorer-item__children",
              style: { height: `${_vm.drawerHeight}px` },
            },
            [
              _c(
                "div",
                {
                  ref: "drawer",
                  staticClass: "explorer-item__children-container",
                },
                [
                  _c(
                    "draggable",
                    {
                      on: { update: _vm.handleReorder },
                      model: {
                        value: _vm.items,
                        callback: function ($$v) {
                          _vm.items = $$v
                        },
                        expression: "items",
                      },
                    },
                    [
                      _vm._l(_vm.children, function (child, i) {
                        return [
                          _vm.hasPermission("selectGrandChild")
                            ? _c(
                                "div",
                                {
                                  key: i,
                                  staticClass: "explorer-item__child",
                                  on: {
                                    click: function ($event) {
                                      return _vm.sendEvent(
                                        "selectGrandChild",
                                        child,
                                        _vm.item,
                                        i,
                                        _vm.index
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.resolveTitle(child)) +
                                        " "
                                    ),
                                  ]),
                                  _vm.hasPermission("removeGrandChild")
                                    ? _c("div", {
                                        staticClass: "icon icon--remove",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.sendEvent(
                                              "removeGrandChild",
                                              child,
                                              _vm.item,
                                              i,
                                              _vm.index
                                            )
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ]),
    _vm.hasPermission("editChild") ||
    _vm.hasPermission("removeChild") ||
    _vm.hasPermission("checkChild") ||
    _vm.active
      ? _c("div", { staticClass: "explorer-item__actions" }, [
          _vm.hasPermission("checkChild")
            ? _c(
                "div",
                {
                  staticClass:
                    "explorer-item__action explorer-item__action--check",
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "explorer-item__check-label d-inline-block",
                    },
                    [_vm._v(_vm._s(_vm.checkLabel))]
                  ),
                  _c("toggle", {
                    staticClass: "d-inline-block",
                    attrs: {
                      disabled: _vm.hasPermission("checkChild"),
                      value: _vm.checked,
                    },
                    on: {
                      input: function ($event) {
                        return _vm.sendEvent("checkChild", {
                          item: _vm.item,
                          value: arguments[0],
                        })
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.hasPermission("editChild")
            ? _c("div", {
                staticClass: "explorer-item__action icon icon--edit",
                on: {
                  click: function ($event) {
                    return _vm.sendEvent("editChild", _vm.item)
                  },
                },
              })
            : _vm._e(),
          _vm.hasPermission("removeChild")
            ? _c("div", {
                staticClass: "explorer-item__action icon icon--remove",
                on: {
                  click: function ($event) {
                    return _vm.sendEvent("removeChild", _vm.item, _vm.index)
                  },
                },
              })
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }