<template>
    <draggable
        v-model="items"
        :options="{
            handle: '.explorer-item__handle',
        }"
        @update="handleUpdate"
    >
        <ExplorerItem
            v-for="(child, i) in items"
            :key="i"
            :index="i"
            :title="resolveChildTitle(child)"
            :children="resolveChildren(child)"
            :checked="resolveChildStatus(child)"
            :checkLabel="childCheckLabel"
            :item="child"
            :resolveChildTitle="resolveGrandChildTitle"
            :permissions="permissions"
            :sortable="sortable"
            @editChild="onEditChild"
            @removeChild="onRemoveChild"
            @selectGrandChild="onSelectGrandChild"
            @removeGrandChild="onRemoveGrandChild"
            @checkChild="onCheckChild"
            @reorder="handleGrandChildReorder(child, i, arguments[0])"
        />
    </draggable>
</template>

<script>
import Draggable from 'vuedraggable'
import ExplorerItem from './explorer_item'

const functionProp = {
    type: Function,
    default: () => true,
}

export default {
    name: 'TinyExplorer',
    props: {
        children: {
            type: Array,
            required: true,
        },
        resolveChildTitle: {
            type: Function,
            default: el => el.title,
        },
        resolveGrandChildTitle: {
            type: Function,
            default: el => el.title,
        },
        resolveChildStatus: {
            type: Function,
            default: el => el.checked,
        },
        resolveChildren: {
            type: Function,
            default: el => el.children,
        },
        childCheckLabel: {
            type: String,
            default: 'Required',
        },
        permissions: {
            type: Array,
            default: () => [
                'viewGrandChildren',
                'editChild',
                'removeChild',
                'removeGrandChild',
                'selectGrandChild',
                'checkChild',
            ],
        },
        sortable: {
            type: Boolean,
            default: true,
        },
        onEditChild: functionProp,
        onRemoveChild: functionProp,
        onSelectGrandChild: functionProp,
        onRemoveGrandChild: functionProp,
        onCheckChild: functionProp,
        onReorder: functionProp,
    },

    data() {
        return {
            items: this.children,
        }
    },

    methods: {
        handleUpdate() {
            this.onReorder(this.items)
        },
        handleGrandChildReorder(item, index, newSet) {
            this.items = this.items.map((el, i) => {
                if (i !== index) {
                    return el
                }

                return {
                    ...el,
                    children: newSet,
                }
            })

            this.handleUpdate()
        },
    },

    watch: {
        children: {
            handler() {
                this.items = this.children
            },
            deep: true,
        },
    },

    components: {
        Draggable,
        ExplorerItem,
    },
}
</script>
