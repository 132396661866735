<template>
    <div class="variation-builder block-xs--md-bottom row no-gutters">
        <template v-if="value.length">
            <div class="variation-builder__table col-12">
                <div>
                    <div class="line">
                        <div
                            v-for="prop in struct"
                            :key="prop.display"
                            :style="{flex: prop.size || 1}"
                            class="variation-builder__table-data"
                        >{{ prop.display }}</div>
                    </div>
                </div>

                <div>
                    <div 
                        v-for="(variation, i) in value" 
                        :key="i" 
                        class="line">
                        <div
                            v-for="prop in struct"
                            :class="{ small }"
                            :key="prop.name"
                            :style="{flex: prop.size || 1}"
                            class="variation-builder__table-data"
                        >
                            <template v-if="prop.name">
                                <form-input 
                                    v-model="variation[prop.name]" 
                                    :disabled="disabled && !canForceEdit(prop.name)" 
                                    :bare="true" 
                                    :full="true" 
                                    spellcheck="true" />
                            </template>

                            <template v-else>
                                <component :is="prop.render(variation, i)"/>
                            </template>
                        </div>

                        <div
                            class="variation-builder__delete-container"
                        >
                            <div 
                                class="variation-builder__button icon icon--remove block-xs--xs-left" 
                                @click="removeVariation(i, variation)"/>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-else>
            <p>There does not appear to be any {{ entityName }}s for this item. Click below to add one.</p>
        </template>

        <text-button
            class="block-xs--md-top col-12"
            type="add"
            @click.native="addVariation">Add {{ entityName }}</text-button>
    </div>
</template>

<script>
import FormInput from './cleverly/FormInput'
import TextButton from './text_button'

export default {
    name: 'ModTable',

    // Components

    components: {
        FormInput,
        TextButton,
    },

    // Props

    props: {
        value: {
            type: Array,
            required: true,
        },

        struct: {
            type: Array,
            required: true,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        forceEditable: {
            type: Array,
            default: () => [],
        },

        entityName: {
            type: String,
            default: 'variation',
        },

        small: {
            type: Boolean,
            default: false,
        },
    },

    // Methods

    methods: {
        addVariation() {
            this.$emit('add')
        },

        removeVariation(index, payload) {
            this.$emit('remove', index, payload)
        },

        canForceEdit(key) {
            return this.forceEditable.indexOf(key) !== -1
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~scss/variables';
@import '~scss/mixins';

.line {
    position: relative;
    display: flex;

    & > * {
        flex: 1;
    }
}

.variation-builder {
    padding-right: 20px;

    &__table {
        border: 1px solid $border_grey;
        border-right: 0;
        border-bottom: 0;

        &-data {
            display: flex;
            justify-content: center;
            background-color: #fff;
            padding: spacing(xs);
            border-bottom: 1px solid $border_grey;
            border-right: 1px solid $border_grey;
            text-align: center;

            & >>> input {
                width: 100%;
            }

            &.small {
                padding: 0;

                input {
                    max-width: 120px;
                }
            }
        }
    }

    &__button {
        cursor: pointer;
    }

    &__table-data, &__delete-container {
        display: flex;
        align-items: center;
    }

    &__delete-container {
        position: absolute;
        top: 50%;
        right: -20px;
        transform: translateY(-50%);
    }
}
</style>
