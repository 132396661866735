<template>
    <div class="item">
        <div class="content-container">
            <line-item class="content">
                {{ item.title }}
            </line-item>

            <div
                :class="{ active }"
                class="arrow"
                @click.stop="toggle"
            />
        </div>

        <div
            :class="{ active }"
            class="children"
        >
            <line-item
                v-for="(child, key) in children"
                :key="key"
                class="child"
            >
                <div>{{ child.title }}</div>
                <div>{{ child.optionCostDisplay || '$0.00' }}</div>
                <div v-if="child.pos_modifierID">{{ child.pos_modifierID }}</div>
                <div>{{ child.startTime | dateStringToTime }} - {{ child.endTime | dateStringToTime }}</div>
            </line-item>
        </div>
    </div>
</template>

<script>
import { dateStringToTime } from 'helpers'
import LineItem from 'components/line_item'

export default {
    name: 'GroupListItem',

    filters: {
        dateStringToTime,
    },

    components: {
        LineItem,
    },

    props: {
        item: {
            type: Object,
            required: true,
        },

        children: {
            type: Array,
            required: true,
        },
    },

    data: () => ({
        active: false,
    }),

    methods: {
        toggle() {
            this.active = !this.active
        },
    },
}
</script>

<style lang="scss" scoped>
@import '~scss/mixins';

.content-container {
    position: relative;
}

.content {
    padding: spacing(sm);
    cursor: pointer;
}

.arrow {
    @include background-image('', 'arrow_down.svg');

    position: absolute;
    top: 0;;
    right: 0;
    bottom: 0;
    width: 90px;
    background-position: center;
    cursor: pointer;

    &:hover {
        background-color: transparentize($grey_light_1, .5);
    }

    &.active {
        @include background-image('', 'arrow_up.svg');

        background-color: transparentize($grey_light_1, .1);
    }
}

.children {
    display: none;
    padding-left: 30px;
    background-color: darken($grey_light_2, 5%);

    &.active {
        display: block;
    }
}

.child {
    display: flex;
    margin-left: -10px;
    padding: spacing(xs);

    & > * {
        flex: 1;
        margin-left: 10px;
    }
}
</style>
