import Vue from 'vue'
import EditingModal from 'components/editing_modal'
import AlertDialog from 'components/alert_dialog'

const getActions = ({ onCancel, onContinue }) => [
    {
        type: 'cancel',
        display: 'Cancel',
        run: onCancel,
    },
    {
        type: 'proceed',
        display: 'Continue',
        run: onContinue,
    },
]

const renderEffectedItem = (h, resolveTitle) => item => {
    return h('li', '- ' + resolveTitle(item))
}

const render = (h, context) => {
    const effectedItems = context.props.effectedItems

    const effectedList = h('ul', effectedItems.map(renderEffectedItem(h, context.props.resolveEffectedTitle)))

    return h(EditingModal, [
        h(AlertDialog, {
            props: {
                title: 'Are you sure?',
                actions: getActions(context.props),
            },
            on: {
                exit: context.props.onCancel,
            },
        }, [
            h('div', `You are about to edit a ${context.props.type} that is associated with the following menu items:`),
            effectedList,
        ]),
    ])
}

export default Vue.component('EditWarning', {
    functional: true,
    props: {
        type: {
            type: String,
            required: true,
        },
        onCancel: {
            type: Function,
            required: true,
        },
        onContinue: {
            type: Function,
            required: true,
        },
        effectedItems: {
            type: Array,
            required: true,
        },
        resolveEffectedTitle: {
            type: Function,
            default: item => item.title,
        },
    },
    render,
})
