var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add-mod-group row no-gutters" },
    [
      _vm.currentView === "index"
        ? [
            _vm.canDo("addNew")
              ? _c(
                  "line-item",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "slab-button",
                      {
                        nativeOn: {
                          click: function ($event) {
                            _vm.currentView = "new"
                          },
                        },
                      },
                      [_vm._v("Create new mod group")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.canDo("addExisting") && _vm.groups.length
              ? [
                  _c(
                    "div",
                    { staticClass: "existing-header col-12" },
                    [
                      _c("h3", [_vm._v("Select an existing Mod Group")]),
                      _c("SearchBar", {
                        attrs: { bound: "" },
                        on: { search: _vm.search },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    _vm._l(_vm.groups, function (group) {
                      return _c("GroupListItem", {
                        key: group.id,
                        attrs: { item: group, children: group.children },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.saveModGroup(group)
                          },
                        },
                      })
                    }),
                    1
                  ),
                ]
              : _vm._e(),
          ]
        : _vm.currentView === "new"
        ? [
            _c("context-title", {
              staticClass: "add-mod-group__context-title col-12",
              attrs: {
                passedActions: _vm.saveActions,
                bare: true,
                title: _vm.identifyingTitle,
              },
            }),
            _c("mod-group-form", {
              staticClass: "col-12",
              attrs: {
                products: _vm.products,
                passedModGroup: _vm.modGroup,
                posMods: _vm.posMods,
                integrated: _vm.integrated,
              },
              on: {
                formChange: _vm.setModGroup,
                removeMod: function ($event) {
                  return _vm.$emit("removeMod", arguments[0])
                },
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }