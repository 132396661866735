var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.modGroup
    ? _c(
        "div",
        {
          staticClass: "store-mod-group-detail row no-gutters",
          attrs: { id: "store-mod-group-detail" },
        },
        [
          _c(
            "key-val-line",
            { attrs: { prop: "Enabled" } },
            [
              _c("toggle", {
                attrs: { slider: true },
                model: {
                  value: _vm.modGroup.available,
                  callback: function ($$v) {
                    _vm.$set(_vm.modGroup, "available", $$v)
                  },
                  expression: "modGroup.available",
                },
              }),
            ],
            1
          ),
          _c(
            "key-val-line",
            { attrs: { prop: "Name" } },
            [
              _c("form-input", {
                attrs: { spellcheck: "true" },
                model: {
                  value: _vm.modGroup.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.modGroup, "title", $$v)
                  },
                  expression: "modGroup.title",
                },
              }),
            ],
            1
          ),
          _c(
            "key-val-line",
            { attrs: { prop: "Required Modifier Group?" } },
            [
              _c("toggle", {
                attrs: { disabled: _vm.modGroup.id != "" },
                model: {
                  value: _vm.modGroup.required,
                  callback: function ($$v) {
                    _vm.$set(_vm.modGroup, "required", $$v)
                  },
                  expression: "modGroup.required",
                },
              }),
            ],
            1
          ),
          _c(
            "key-val-line",
            { attrs: { prop: _vm.limitLabel } },
            [
              _c("form-input", {
                model: {
                  value: _vm.modGroup.selection,
                  callback: function ($$v) {
                    _vm.$set(_vm.modGroup, "selection", $$v)
                  },
                  expression: "modGroup.selection",
                },
              }),
            ],
            1
          ),
          _c(
            "key-val-line",
            { attrs: { prop: "Mods" } },
            [
              _c("variation-table", {
                attrs: {
                  value: _vm.mods,
                  struct: _vm.modStruct,
                  disabled: true,
                  forceEditable: _vm.editableModProps,
                  entityName: "mod",
                },
                on: {
                  add: function ($event) {
                    _vm.modModalEnabled = true
                  },
                  remove: _vm.removeMod,
                },
                model: {
                  value: this.modGroup.children,
                  callback: function ($$v) {
                    _vm.$set(this.modGroup, "children", $$v)
                  },
                  expression: "this.modGroup.children",
                },
              }),
            ],
            1
          ),
          _vm.pasteEnabled
            ? _c(
                "key-val-line",
                { attrs: { prop: "Mod Paste" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-textarea", {
                        model: {
                          value: _vm.pasteData,
                          callback: function ($$v) {
                            _vm.pasteData = $$v
                          },
                          expression: "pasteData",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c("v-btn", { on: { click: _vm.parsePaste } }, [
                        _vm._v("Load"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "col" },
            [
              _vm.modModalEnabled
                ? _c(
                    "editing-modal",
                    [
                      _c("editing-modal-header-simple", {
                        attrs: { title: "Add a Mod" },
                        on: {
                          closeModal: function ($event) {
                            _vm.modModalEnabled = false
                          },
                        },
                      }),
                      _c("add-mod", {
                        attrs: {
                          products: _vm.products,
                          integrated: _vm.integrated,
                        },
                        on: { save: _vm.addMod, savePosItems: _vm.addPosMods },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }