var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "item" }, [
    _c(
      "div",
      { staticClass: "content-container" },
      [
        _c("line-item", { staticClass: "content" }, [
          _vm._v(" " + _vm._s(_vm.item.title) + " "),
        ]),
        _c("div", {
          staticClass: "arrow",
          class: { active: _vm.active },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.toggle.apply(null, arguments)
            },
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "children", class: { active: _vm.active } },
      _vm._l(_vm.children, function (child, key) {
        return _c("line-item", { key: key, staticClass: "child" }, [
          _c("div", [_vm._v(_vm._s(child.title))]),
          _c("div", [_vm._v(_vm._s(child.optionCostDisplay || "$0.00"))]),
          child.pos_modifierID
            ? _c("div", [_vm._v(_vm._s(child.pos_modifierID))])
            : _vm._e(),
          _c("div", [
            _vm._v(
              _vm._s(_vm._f("dateStringToTime")(child.startTime)) +
                " - " +
                _vm._s(_vm._f("dateStringToTime")(child.endTime))
            ),
          ]),
        ])
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }