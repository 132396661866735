<template>
    <div
        v-if="modGroup"
        id="store-mod-group-detail"
        class="store-mod-group-detail row no-gutters"
    >
        <key-val-line prop="Enabled">
            <toggle v-model="modGroup.available" :slider="true" />
        </key-val-line>

        <key-val-line prop="Name">
            <form-input v-model="modGroup.title" spellcheck="true" />
        </key-val-line>

        <key-val-line prop="Required Modifier Group?">
            <toggle
                v-model="modGroup.required"
                :disabled="modGroup.id != ''"
            />
        </key-val-line>

        <key-val-line :prop="limitLabel">
            <form-input v-model="modGroup.selection" />
        </key-val-line>

        <key-val-line prop="Mods">
            <variation-table
                v-model="this.modGroup.children"
                :value="mods"
                :struct="modStruct"
                :disabled="true"
                :forceEditable="editableModProps"
                entityName="mod"
                @add="modModalEnabled = true"
                @remove="removeMod"
            />
        </key-val-line>
        <key-val-line v-if="pasteEnabled" prop="Mod Paste">
            <v-row no-gutters
                ><v-textarea v-model="pasteData"> </v-textarea
            ></v-row>
            <v-row no-gutters><v-btn @click="parsePaste">Load</v-btn></v-row>
        </key-val-line>
        <div class="col">
            <editing-modal v-if="modModalEnabled">
                <editing-modal-header-simple
                    title="Add a Mod"
                    @closeModal="modModalEnabled = false"
                />

                <add-mod
                    :products="products"
                    :integrated="integrated"
                    @save="addMod"
                    @savePosItems="addPosMods"
                />
            </editing-modal>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import clone from "clone";
import KeyValLine from "components/key_val_line";
import Toggle from "components/cleverly/Toggle";
import FormInput from "components/cleverly/FormInput";
import LineItem from "components/line_item";
import EditingModal from "components/editing_modal";
import Mods from "components/mods/mods";
import EditingModalHeaderSimple from "components/editing_modal_header_simple";
import AddMod from "components/AddMod";
import TinyExplorer from "components/tiny_explorer/explorer";
import VariationTable from "components/ModTable";
import TimePicker from "components/TimePicker";
import { mapGetters } from "vuex";

export default {
    name: "ModGroupForm",

    // Components

    components: {
        KeyValLine,
        Toggle,
        FormInput,
        LineItem,
        EditingModal,
        Mods,
        EditingModalHeaderSimple,
        AddMod,
        TinyExplorer,
        VariationTable,
    },

    // Props

    props: {
        products: Array,
        passedModGroup: [Object, Boolean],
        posMods: {
            type: Array,
            default: () => [],
        },
        integrated: {
            type: Boolean,
            default: false,
        },
    },

    // Data

    data() {
        const passedGroupChildren = this.passedModGroup
            ? this.passedModGroup.children
            : false;

        return {
            limitLabel:
                this.passedModGroup && this.passedModGroup.required
                    ? "Choice Limit"
                    : "Option Limit",
            passedGroupChildren,
            modModalEnabled: false,
            activePosModGroup: false,
            isPos: this.posMods.length > 0,
            modGroup: {
                available: true,
                title: "",
                startTime: "1/1/1900 8:00 am",
                endTime: "1/1/1900 11:30 am",
                id: "",
                displayOrder: "1",
                selection: "0",
                pos_modifierGroupID: "", // Might break things
                children: [],
            },
            posModStruct: {
                id: "",
                order: "1",
                cost: 0,
                available: true,
                title: "",
                startTime: "12:00 AM",
                endTime: "12:00 PM",
                groupName: "",
                selected: false,
                pos_modifierID: "",
            },
            pasteData: "",
        };
    },

    // Computed

    computed: {
        editableModProps() {
            var editableProps = ["title"];
            if (!this.integrated) {
                editableProps = editableProps.concat([
                    "cost",
                    "pos_modifierID",
                ]);
            }
            if (
                this.integrated &&
                this.store.POSIntegrationSupportsCustomPricing
            ) {
                editableProps.push("cost");
            }
            return editableProps;
        },
        pasteEnabled() {
            return this.user.email.toLowerCase()  == 'nick@getgrab.com' || this.user.email.toLowerCase() == 'chris@servy.us';
        },
        modStruct() {
            const context = this;

            return [
                { display: "Name", name: "title", size: 4 },
                { display: "Price", name: "cost", size: 1 },
                {
                    display: "POS ID",
                    size: 1,
                    render: (item, i) => {
                        return Vue.extend({
                            data: () => ({ item }),
                            template: `
                                <span style="opacity: 0.5;">{{ item.pos_modifierID || 'N/A' }}</span>
                            `,
                        });
                    },
                },
                {
                    display: "Grab ID",
                    size: 1,
                    render: (item, i) => {
                        return Vue.extend({
                            data: () => ({ item }),
                            template: `
                                <span style="opacity: 0.5;">{{ item.id || 'N/A' }}</span>
                            `,
                        });
                    },
                },
                {
                    display: "Time",
                    size: 2,
                    render: (item, i) => {
                        return Vue.extend({
                            components: {
                                Toggle,
                                TimePicker,
                            },
                            data: () => {
                                const allDay =
                                    moment
                                        .utc(item.startTime)
                                        .format("h:mm A") === "12:01 AM" &&
                                    moment
                                        .utc(item.endTime)
                                        .format("h:mm A") === "11:59 PM";

                                return {
                                    item,
                                    allDay,
                                };
                            },

                            watch: {
                                allDay(value) {
                                    if (value) {
                                        this.item.startTime = moment
                                            .utc("12:01 AM", "h:mm A")
                                            .format("[/Date(]xZZ[))/]");
                                        this.item.endTime = moment
                                            .utc("11:59 PM", "h:mm A")
                                            .format("[/Date(]xZZ[))/]");

                                        return;
                                    }
                                },
                            },
                            methods: {
                                handleUpdate() {
                                    context.modGroup.children =
                                        context.modGroup.children.map(
                                            (el, index) => {
                                                if (index !== i) {
                                                    return el;
                                                }

                                                return this.item;
                                            }
                                        );
                                },
                            },
                            template: `
                                <div>
                                    <v-layout row ml-0><Toggle v-model="allDay" /><div style="padding-left: 5px;">All Day</div></v-layout>

                                    <div v-if="!allDay" style="padding-top: 10px;" class="mod-group-time">
                                        <TimePicker maxWidth="100%" v-model="item.startTime" @input="handleUpdate" /> to <TimePicker maxWidth="100%" v-model="item.endTime" @input="handleUpdate" />
                                    </div>
                                </div>
                            `,
                        });
                    },
                },
            ];
        },

        mods() {
            return this.modGroup.children.map((mod) => {
                return {
                    ...mod,
                    pos_modifierID:
                        !mod.pos_modifierID || mod.pos_modifierID === ""
                            ? "N/A"
                            : mod.pos_modifierID,
                };
            });
        },
        ...mapGetters(["pos", "store", "user"]),
    },

    // Watch

    watch: {
        modGroup: {
            handler() {
                this.$emit("formChange", this.modGroup);
            },
            deep: true,
        },
    },

    // Hooks

    created() {
        if (this.passedModGroup) {
            this.modGroup = clone(this.passedModGroup);
        }
    },

    // Methods

    methods: {
        parsePaste() {
            var lines = this.pasteData.split(/\r?\n/);
            var result = [];
            for (var line = 0; line < lines.length; line++) {
                console.log(lines[line]);
                var tabs = lines[line].split(/\t/);
                console.log(tabs);
                var mod = {
                    title: tabs[0],
                    cost: tabs[2].trim().replace("$", ""),
                    pos_modifierID: tabs[1],
                    order: line,
                };
                if (mod.cost == "-") mod.cost = 0
                mod.cost = parseFloat(mod.cost)
                mod.startTime = moment
                    .utc("12:01 AM", "h:mm A")
                    .format("[/Date(]xZZ[))/]");
                mod.endTime = moment
                    .utc("11:59 PM", "h:mm A")
                    .format("[/Date(]xZZ[))/]");
                result.push(mod);
            }
            this.modGroup.children = result;
        },
        onModAddClick() {
            this.modModalEnabled = true;
        },

        addMod(mod) {
            const endOrder = this.getModOrderEnd(this.modGroup.children);

            mod.order = String(endOrder + 1);
            mod.startTime = moment
                .utc("12:01 AM", "h:mm A")
                .format("[/Date(]xZZ[))/]");
            mod.endTime = moment
                .utc("11:59 PM", "h:mm A")
                .format("[/Date(]xZZ[))/]");
            // mod.id = this.modGroup.id || ''

            this.modGroup.children = [...this.modGroup.children, mod];
            this.modModalEnabled = false;
        },

        addPosMods(mods) {
            mods.forEach(this.addPosMod);
        },

        addPosMod(mod) {
            /* eslint-disable camelcase */

            const { cost, pos_modifierID, pos_modifierName } = mod;

            const newMod = Object.assign({}, clone(this.posModStruct), {
                cost,
                pos_modifierID,
                // productID: pos_productID,
                title: pos_modifierName,
                groupName: this.modGroup.title,
            });

            this.addMod(newMod);

            /* eslint-enable camelcase */
        },

        removeMod(index, mod) {
            this.$emit("removeMod", mod);

            const mods = this.modGroup.children.filter((el) => {
                return el.title !== mod.title;
            });

            this.modGroup.children = mods;
        },

        getModOrderEnd(mods) {
            const sorted = []
                .concat(mods)
                .sort((a, b) => Number(b.order) - Number(a.order));

            return sorted.length ? Number(sorted[0].order) : 0;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";

.add-mod-button {
    color: $brand_color;
}
</style>
