// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../img/arrow_down.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../img/arrow_up.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".content-container[data-v-bc024f20] {\n  position: relative;\n}\n.content[data-v-bc024f20] {\n  padding: 18px;\n  cursor: pointer;\n}\n.arrow[data-v-bc024f20] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  width: 90px;\n  background-position: center;\n  cursor: pointer;\n}\n.arrow[data-v-bc024f20]:hover {\n  background-color: rgba(228, 228, 228, 0.5);\n}\n.arrow.active[data-v-bc024f20] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n  background-color: rgba(228, 228, 228, 0.9);\n}\n.children[data-v-bc024f20] {\n  display: none;\n  padding-left: 30px;\n  background-color: #ececec;\n}\n.children.active[data-v-bc024f20] {\n  display: block;\n}\n.child[data-v-bc024f20] {\n  display: flex;\n  margin-left: -10px;\n  padding: 12px;\n}\n.child > *[data-v-bc024f20] {\n  flex: 1;\n  margin-left: 10px;\n}", ""]);
// Exports
module.exports = exports;
