<template>
    <div class="explorer-item">
        <div class="explorer-item__content">
            <div class="explorer-item__header" @click="handleHeaderClick">
                <div class="explorer-item__handle-title">
                    <div v-if="sortable" class="explorer-item__handle icon icon--handle"></div>

                    <div class="explorer-item__title">
                        {{ title }}
                    </div>
                </div>

                <div
                v-if="hasPermission('viewGrandChildren')"
                class="explorer-item__indicator icon"
                :class="{'icon--plus': !active, 'icon--minus': active}"
                ></div>
            </div>

            <div
            v-if="hasPermission('viewGrandChildren')"
            class="explorer-item__children"
            :style="{height: `${drawerHeight}px`}"
            >
                <div ref="drawer" class="explorer-item__children-container">
                    <draggable
                        v-model="items"
                        @update="handleReorder"
                    >
                        <template v-for="(child, i) in children">
                            <div
                            v-if="hasPermission('selectGrandChild')"
                            class="explorer-item__child"
                            :key="i"
                            @click="sendEvent('selectGrandChild', child, item, i, index)"
                            >
                                <span>
                                    {{ resolveTitle(child) }}
                                </span>

                                <div
                                v-if="hasPermission('removeGrandChild')"
                                class="icon icon--remove"
                                @click.stop="sendEvent('removeGrandChild', child, item, i, index)"
                                ></div>
                            </div>
                        </template>
                    </draggable>
                </div>
            </div>
        </div>

        <div
        v-if="(hasPermission('editChild') || hasPermission('removeChild') || hasPermission('checkChild')) || active"
        class="explorer-item__actions">
            <div
            v-if="hasPermission('checkChild')"
            class="explorer-item__action explorer-item__action--check"
            >
                <span class="explorer-item__check-label d-inline-block">{{ checkLabel }}</span>
                <toggle :disabled="hasPermission('checkChild')" class="d-inline-block" @input="sendEvent('checkChild', {item, value: arguments[0]})" :value="checked" />
            </div>

            <div
            v-if="hasPermission('editChild')"
            class="explorer-item__action icon icon--edit"
            @click="sendEvent('editChild', item)"
            ></div>

            <div
            v-if="hasPermission('removeChild')"
            class="explorer-item__action icon icon--remove"
            @click="sendEvent('removeChild', item, index)"
            ></div>
        </div>
    </div>
</template>

<script>
import Draggable from 'vuedraggable'
import Toggle from 'components/cleverly/Toggle'

export default {
    name: 'TinyExplorerItem',
    props: {
        item: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        children: {
            type: Array,
            default: () => [],
        },
        checked: {
            type: Boolean,
            default: false,
        },
        resolveChildTitle: {
            type: Function,
            default: el => el.title,
        },
        permissions: {
            type: Array,
            default: [],
        },
        checkLabel: {
            type: String,
            default: 'Required',
        },
        sortable: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            items: this.children,
            active: false,
            drawerHeight: 0,
        }
    },
    methods: {
        resolveTitle(input) {
            return this.resolveChildTitle(input)
        },
        handleHeaderClick() {
            if (this.hasPermission('viewGrandChildren') && !this.active) {
                this.active = true
                this.drawerHeight = this.resolveDrawerHeight()
            } else {
                this.active = false
                this.drawerHeight = 0
            }
        },
        resolveDrawerHeight() {
            return this.$refs.drawer.clientHeight
        },
        sendEvent(event, ...args) {
            this.$emit(event, ...args)
        },
        hasPermission(action) {
            return (this.permissions.indexOf(action) !== -1)
        },
        handleReorder() {
            this.$emit('reorder', this.items)
        },
    },
    watch: {
        children: {
            handler() {
                this.items = this.children

                setTimeout(() => {
                    if (this.active) {
                        this.drawerHeight = this.resolveDrawerHeight()
                    }
                }, 50)
            },

            deep: true,
        },
    },
    components: {
        Draggable,
        Toggle,
    },
}
</script>

<style lang="scss" scoped>
@import '~scss/variables';
@import '~scss/mixins';

.explorer-item {
    display: flex;
    margin-bottom: spacing(xs);

    .icon {
        cursor: pointer;
    }

    &__content {
        background-color: #fff;
        border: 1px solid $border_light_grey;
        border-radius: 2px;
        width: 50%;
    }

    &__header, &__handle-title {
        display: flex;
        align-items: center;
    }

    &__header {
        justify-content: space-between;
        cursor: default;
    }

    &__handle, &__title, &__indicator, &__child, &__action {
        padding: spacing(xxs) spacing(xs);
    }

    &__handle.icon, &__child {
        cursor: grab;
    }

    &__children {
        height: 0;
        overflow-y: auto;
        transition: height $transition_short;
    }

    &__child {
        display: flex;
        padding-left: spacing(xxs) * 6;
        padding-right: spacing(xxs);
        transition: box-shadow $transition_short;
        align-items: center;
        justify-content: space-between;

        &:hover {
            background-color: $grey_light_2;
            box-shadow: inset 2px 0 0 $brand_color;
        }
    }

    &__actions {
        display: flex;
        margin-left: spacing(xs);
    }

    &__action {
        padding-top: spacing(lg);

        &--check {
            padding-top: spacing(xxs);
        }
    }

    &__check-label {
        font-size: .8em;
        padding-right: 5px;
    }
}
</style>
